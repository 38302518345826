<template>
  <div>
    <p class="nodata" v-if="bookData.length==0">暂无数据</p>
    <book-list :set="false" :datalist="bookData"></book-list>
    <el-pagination
      background
      v-if="total>pageCount"
      @current-change="handleCurrentChange"
      layout="prev, pager, next"
      :page-size.sync="pageCount"
      :current-page="currentPage"
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
import BookList from "../components/Booklist.vue";
import { booksRequest } from "@/api/project.js";
export default {
  components: {
    BookList
  },
  data() {
    return {
      bookData:[],
      currentPage:1,
      total:2,
      pageCount:10,
      searchinp:''
    };
  },  
  watch:{
    $route: {
        handler(newName, oldName) {
          if (this.$route.query.wd) {
              this.searchinp=newName.query.wd
          }
           this.getBook();
          return
        },
        immediate: true
      }

  },
  methods: {
    async getBook(val) {
      try {
        val=val || '1';
        var oData;
        if(this.searchinp){
           oData={
            page: val,
            key: this.searchinp
          }
        }else{
          oData={
            page: val
          }
        }
       
        const result = await booksRequest(oData);
        if (result.status == 200) {
          this.bookData = result.data.list;
          this.total=result.data.totalCount
          this.pageCount=result.data.pageCount
        }
      } catch (err) {}
    },
    handleCurrentChange(val) {
      this.getBook(val);
    }
  },
 
};
</script>
<style  lang="less" scoped>
.el-pagination{
text-align: right;
margin:20px;
}

/deep/.booklist{
  margin:0 -10px;
  padding:30px 0;
  li {
     width: 16.66667%;
    .onebox{
      box-shadow: none;
      border:none;
      border-radius: 2px;
         
    }
  }
  }

.nodata{
    margin: 0 auto;
    padding: 100px 0 50px;
    text-align: center;
    color: #888;
}
</style>